<template>
    <v-container>
        <ASuccessWithoutReload :api="this.api" @close="fetch()"/>
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading && isFirstLoading ">
        </v-skeleton-loader>
        <v-data-table
        v-if="filteredData!=null"
        :items="filteredData"
        :loading="api.isLoading"
        :headers="dataHeaders"
        :footer-props="{
            showFirstLastPage:true,
        }"
        class="elevation-1 mt-5">
        <template v-slot:item.index="{item}">
            {{ data.indexOf(item)+1 }}
        </template>
        <template
            v-slot:item.email="{item}">
            <ACopyAbleText
            :text="item.email"/>
        </template>
        <template
            v-slot:item.user_name="{item}">
            <ACopyAbleText
            :text="item.user_name"/>
        </template>
        <template v-slot:item.name="{item}">
            <router-link :to="({name:'PageCompaniesDetail',params:{id:item.companies_id}})">
                {{ item.name }}
            </router-link>
        </template>
        <template v-slot:item.created_at="{item}">
            <!-- <span v-if="item.created_at!=null"> -->
                <!-- {{ item.created_at.split(" ")[0] }}
                {{ convertTimeZone(item.created_at).split(" ")[3]}} {{ convertTimeZone(item.created_at).split(" ")[4] }} -->
            <!-- </span> -->
            <ADateFormat
                :date="item.created_at"/>
        </template>
        <template
            v-slot:item.mobile="{item}">
            <ACopyAbleText :text="item.mobile"/>
        </template>
        <template v-slot:item.status="{item}">
            <div
                v-if="$permission.allowedAdminOperationRole($store.getters.getRole)">
                <v-btn v-if="item.status==1" color="green white--text" width="90" small
                @click="activateAccountStatus(item.user_id,0)">
                    Active
                </v-btn>
                <v-btn v-if="item.status ==0" color="red white--text" width="90" small 
                @click="activateAccountStatus(item.user_id,1)">
                    Deactive
                </v-btn>
            </div>
            <div
                class="d-flex justify-center"
                v-else>
                <v-chip
                    v-if="item.status==1" 
                    width="90"
                    small
                    outlined
                    color="green white--text">
                    Active
                </v-chip>
                <v-chip
                    v-if="item.status ==0"
                    width="90"
                    small
                    outlined
                    color="red white--text">
                    Deactive
                </v-chip>
            </div>
        </template>
        <template v-slot:item.stripe_status="{item}">
            <!-- {{ item.stripe_status }} -->
            <div
                v-if="item.stripe_status!=null">
                <v-chip outlined v-if="item.stripe_status.toLowerCase()==='canceled'"
                width="90"
                small
                color="red white--text">
                {{ item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1) }}
                </v-chip>
                <v-chip
                width="90"
                small
                outlined
                v-else-if="item.stripe_status.toLowerCase()==='active'"
                color="green white--text">
                {{ item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1) }}
                </v-chip>
                <v-chip
                    width="90"
                    small
                    outlined
                    color="orange white--text"
                    v-else>
                    {{ item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1) }}
                </v-chip>
            </div>

        </template>
        <template v-slot:top>
                <v-toolbar width="auto" height="auto" color="py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Users
                                </v-toolbar-title>
                            </v-row>
                            <AFilterWithoutRefresh
                                @filter="(data)=>{
                                    filteredData = data;
                                }"
                                @reset="(data)=>{
                                    filteredData = data;
                                }"
                                @refresh="fetch"
                                :data="data"
                                :filterHeaders="dataHeaders"/>
                            <div class="black--text">
                                Total users : {{ totalData }}
                            </div>
                        </v-col>
                       
                    </v-row>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>
<script>
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import AFilterWithoutRefresh from '../../components/common/filter/AFilterWithoutRefresh.vue';

export default{
    components : {
        AFilterWithoutRefresh,
        ASuccessWithoutReload,
    },

    data: ()=>({
        isFirstLoading:true,
        searchField:null,
        data:null,
        filteredData:null,
        totalData:null,
        dataHeaders:[
        {
            text:"#",
            value:"index",
            align:"start",
        },
        {
            text:'Name',
            value:'user_name',
        },
        {
            text:'Email',
            value:'email',
        },
        {
            text:'No. phone',
            value:'mobile',
        },
        {
            text:'Company',
            value:'name'
        },
        {
            text:'taxPOD Account status',
            value:'status',
            type:'boolean',
            options:[
                {
                    text:'Active',
                    value:1
                },
                {
                    text:"Deactive",
                    value:0
                },
            ]
        },
        {
            text:'Stripe Subscription status',
            value:'stripe_status'
        },
        {
            text:'Created date',
            value:'created_at',
        },
        ],
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getClient"){
                    this.data = resp.data.users;
                    this.filteredData = this.$_.clone(this.data);
                    this.totalData = resp.data.total;
                    this.isFirstLoading = false;
            }
            if(resp.class ==="updateUserStatus") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully update client status";

            }
            this.api.isLoading = false;
            this.api.isError = false;
            // this.isSucessful = true;
        }

    },
    mounted(){
        this.fetch();
    },
    methods:{   
        fetch(){    
            this.api.isSuccesful = false;
            let fetchCompaniesUsersApi = this.fetchCompanyUsers();
            this.$api.fetch(fetchCompaniesUsersApi);
        },
        fetchCompanyUsers(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/client";

            if(this.$store.getters.getRole=='sales_team_lead' || this.$store.getters.getRole=='salesperson'||this.$store.getters.getRole=='marketing'){
                tempApi.url = process.env.VUE_APP_SERVER_API+"/users/client?restricted=true&userId="+this.$store.getters.getUserId;
            }


            return tempApi;
        },
        redirectToCompany(companyId){
            let route = this.$router.resolve({name:'PageCompaniesDetail',params:{'id':companyId}});
            window.open(route.href);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
        activateAccountStatus(userId,status) {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/status/id/"+userId;
            tempApi.params = {
                'status':status,
            }
            this.$api.fetch(tempApi);
        }
    }
}


</script>