var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ASuccessWithoutReload',{attrs:{"api":this.api},on:{"close":function($event){return _vm.fetch()}}}),(_vm.api.isLoading && _vm.isFirstLoading )?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),(_vm.filteredData!=null)?_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"items":_vm.filteredData,"loading":_vm.api.isLoading,"headers":_vm.dataHeaders,"footer-props":{
        showFirstLastPage:true,
    }},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.data.indexOf(item)+1)+" ")]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('ACopyAbleText',{attrs:{"text":item.email}})]}},{key:"item.user_name",fn:function(ref){
    var item = ref.item;
return [_c('ACopyAbleText',{attrs:{"text":item.user_name}})]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":({name:'PageCompaniesDetail',params:{id:item.companies_id}})}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('ADateFormat',{attrs:{"date":item.created_at}})]}},{key:"item.mobile",fn:function(ref){
    var item = ref.item;
return [_c('ACopyAbleText',{attrs:{"text":item.mobile}})]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [(_vm.$permission.allowedAdminOperationRole(_vm.$store.getters.getRole))?_c('div',[(item.status==1)?_c('v-btn',{attrs:{"color":"green white--text","width":"90","small":""},on:{"click":function($event){return _vm.activateAccountStatus(item.user_id,0)}}},[_vm._v(" Active ")]):_vm._e(),(item.status ==0)?_c('v-btn',{attrs:{"color":"red white--text","width":"90","small":""},on:{"click":function($event){return _vm.activateAccountStatus(item.user_id,1)}}},[_vm._v(" Deactive ")]):_vm._e()],1):_c('div',{staticClass:"d-flex justify-center"},[(item.status==1)?_c('v-chip',{attrs:{"width":"90","small":"","outlined":"","color":"green white--text"}},[_vm._v(" Active ")]):_vm._e(),(item.status ==0)?_c('v-chip',{attrs:{"width":"90","small":"","outlined":"","color":"red white--text"}},[_vm._v(" Deactive ")]):_vm._e()],1)]}},{key:"item.stripe_status",fn:function(ref){
    var item = ref.item;
return [(item.stripe_status!=null)?_c('div',[(item.stripe_status.toLowerCase()==='canceled')?_c('v-chip',{attrs:{"outlined":"","width":"90","small":"","color":"red white--text"}},[_vm._v(" "+_vm._s(item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1))+" ")]):(item.stripe_status.toLowerCase()==='active')?_c('v-chip',{attrs:{"width":"90","small":"","outlined":"","color":"green white--text"}},[_vm._v(" "+_vm._s(item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1))+" ")]):_c('v-chip',{attrs:{"width":"90","small":"","outlined":"","color":"orange white--text"}},[_vm._v(" "+_vm._s(item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1))+" ")])],1):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"width":"auto","height":"auto","color":"py-3","flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3 mx-3"},[_c('v-row',{staticClass:"mb-3"},[_c('v-toolbar-title',{staticClass:"font-weight-bold text-h4 my-3"},[_vm._v(" Users ")])],1),_c('AFilterWithoutRefresh',{attrs:{"data":_vm.data,"filterHeaders":_vm.dataHeaders},on:{"filter":function (data){
                                _vm.filteredData = data;
                            },"reset":function (data){
                                _vm.filteredData = data;
                            },"refresh":_vm.fetch}}),_c('div',{staticClass:"black--text"},[_vm._v(" Total users : "+_vm._s(_vm.totalData)+" ")])],1)],1)],1)]},proxy:true}],null,false,3576292343)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }